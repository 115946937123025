import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { setAuthenticatedInfoAndSaveToLocalStorage } from "store/AuthServer/actionCreators";
import { LoadingOverlay } from "presentational-components";
import getEnv from "utils/Envs";
import constants from "constants/ErrorMessages";

const queryString = require("query-string");

/**
 * This component handles communication between Facet and Authserver after a user submits
 * the login/consent forms on the Authserver side.
 */
const AuthRedirect = (props) => {
  const [hasErrored, setHasErrored] = useState(false);
  const accessTokenInfo = useSelector(
    (state) => state.authServerReducer.accessTokenInfo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    /* We are already logged in by another component */
    if (accessTokenInfo) {
      return;
    }

    const parseRedirectForAuthCode = () => {
      const paramsObj = queryString.parse(props.location.search);
      const code = paramsObj.code;

      if (code !== undefined) {
        const params = new URLSearchParams();
        params.append("grant_type", "authorization_code");
        params.append("client_id", `${getEnv("REACT_APP_FACET_CLIENT_ID")}`);
        params.append("code", code?.toString());
        params.append("redirect_uri", `${getEnv("REACT_APP_REDIRECT_URI")}`);
        return params;
      }
    };
    const handleError = (e) => {
      toast.error(constants.DEFAULT_ERROR_MESSAGE);
      setHasErrored(true);
      localStorage.setItem("refreshToken", "");
    };

    const getAuthToken = (authData) => {
      return axios
        .post(`${getEnv("REACT_APP_AUTHSERVER_URL")}/oauth2/token`, authData)
        .then((response) => {
          const { access_token, id_token, refresh_token } = response.data;
          return { access_token, id_token, refresh_token };
        })
        .catch(handleError);
    };

    const login = async () => {
      const authData = parseRedirectForAuthCode();
      const authRes = await getAuthToken(authData);
      localStorage.setItem("refreshToken", authRes?.refresh_token || "");
      dispatch(
        setAuthenticatedInfoAndSaveToLocalStorage(
          authRes?.access_token,
          authRes?.id_token,
        )
      );
    };

    login();
  }, [accessTokenInfo, props.location.search, dispatch]);

  if (hasErrored) {
    return <Redirect to="/login" />;
  }

  if (!accessTokenInfo) {
    return <LoadingOverlay />;
  }

  const redirectUrl = sessionStorage.getItem("redirectUrl");
  sessionStorage.removeItem("redirectUrl");

  return redirectUrl ? <Redirect to={redirectUrl} /> : <Redirect to="/" />;
};

export default AuthRedirect;
