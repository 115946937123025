import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { logoutUser } from "../../store/AuthServer/actionCreators";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);
  return <Redirect to="/login" />;
};

export default Logout;
