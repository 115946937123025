import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { rootReducer } from "store";
import { history } from "./history";
import App from "./App";

import "./index.css";
import AppApolloProvider from "./AppApolloProvider";
import HydrationStation from "./HydrationStation";
import * as serviceWorker from "./serviceWorker";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const renderApp = async () => {
  const app = (
    <Provider store={store}>
      <Router history={history}>
        <HydrationStation>
          <AppApolloProvider>
            <App />
          </AppApolloProvider>
        </HydrationStation>
      </Router>
    </Provider>
  );
  ReactDOM.render(app, document.getElementById("root"));
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
