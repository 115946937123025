import axios from "axios";
import getEnv from "../utils/Envs";
import { getJwtToken } from "../utils/Axios";
import { store } from "../index";
import { toast } from "react-toastify";
import { logoutUser, setAuthenticatedInfoAndSaveToLocalStorage } from "../store/AuthServer/actionCreators";

export const uploadApi = axios.create({
  baseURL: getEnv("REACT_APP_EMAIL_API"),
});

uploadApi.interceptors.request.use((config) => {
  config.headers['authorization'] = `${getJwtToken()}`;
  return config;
});

export const getRefreshToken =  () => (dispatch, getState) => {
  const authData = new URLSearchParams();
  authData.append("grant_type", "refresh_token");
  authData.append("client_id", `${getEnv("REACT_APP_FACET_CLIENT_ID")}`);
  authData.append(
    "refresh_token",
    localStorage.getItem("refreshToken") || ""
  );
  return axios.post(`${getEnv("REACT_APP_AUTHSERVER_URL")}/oauth2/token`, authData)
    .then((response) => {
      const { access_token, id_token } = response.data;
      dispatch(setAuthenticatedInfoAndSaveToLocalStorage(access_token, id_token));
      return { access_token, id_token };
    })
    .catch((err) => {
      dispatch(logoutUser());
      throw(err);
    });
};

uploadApi.interceptors.request.use(
  async config => {
    config.headers = {
      authorization: `${getJwtToken()}`,
    }
    return config;
  },
  error => Promise.reject(error)
  );


uploadApi.interceptors.response.use((response) => {
  return response
}, async function (error) {
  console.log('ERROR FROM UPLOAD API: ', error);
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const { access_token } = await store.dispatch(getRefreshToken());
      uploadApi.defaults.headers['Authorization'] = `${access_token}`;
      return uploadApi(originalRequest);
    } catch (e) {
      console.error('ERROR REFRESHING TOKEN: ', e);
      toast.info('Session expired please logout');
      return Promise.reject(e);
    }
  }
  return Promise.reject(error);
});