import React, { useEffect, useState } from "react";
import { FlatfileButton } from "@flatfile/react";
import classNames from "classnames";
import { toast } from "react-toastify";
import getEnv from "utils/Envs";
import {
  convertSchemaToFields,
  generateFieldHooks,
  generateOnRecordChange,
} from "./uploaderPOC";
import styles from "./Upload.module.scss";
import { useQuery } from "@apollo/client";
import { GET_SCHEMA } from "graphql/data";
import { META_DATA_API_CONTEXT_NAME } from "../../AppApolloProvider";
import { Spinner } from "react-bootstrap";
import { uploadApi } from "../../api/upload";
import axios from "axios";

const ACTION_SINGLE = "SINGLE";
const ACTION_MULTIPART = "MULTIPART";
const ACTION_CANCEL_MULTIPART = "CANCEL_MULTIPART";
const ACTION_COMPLETE_MULTIPART = "COMPLETE_MULTIPART";

const Upload = ({ schemaName, dataAssetId }) => {
  const [disableButton] = useState(false);
  const [parsingError, setParsingError] = useState(false);
  const [schema, setSchema] = useState(null);

  const escapeJsonString = (jsonString) => {
    return jsonString
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
  };

  const { data: schemaData, loading, error } = useQuery(GET_SCHEMA, {
    context: {
      clientName: META_DATA_API_CONTEXT_NAME,
    },
    skip: !schemaName,
    variables: {
      schemaName,
      latestVersion: true,
    },
  });

  useEffect(() => {
    if (schemaData?.getSchema?.SchemaDefinition) {
      try {
        const escapedJsonString = escapeJsonString(
          schemaData?.getSchema?.SchemaDefinition
        );
        const parsedSchema = JSON.parse(escapedJsonString);
        setSchema(parsedSchema);
      } catch (e) {
        console.error(e);
        setParsingError(true);
      }
    }
  }, [schemaData?.getSchema?.SchemaDefinition]);

  const primaryButton = {
    backgroundColor: "#0f544c",
    color: "#ffffff",
    border: "none",
    padding: "1rem 3rem",
    fontSize: "1.6rem",
    fontWeight: "400",
    borderRadius: "0.5rem",
    ":hover": {
      backgroundColor: "#167a6e",
      border: "none",
    },
  };

  const generatePresignedUrlForUuid = (uuid) =>
    `${getEnv("REACT_APP_DATA_SERVICE_URL")}/presigned-url/v1/data/${uuid}`;

  const getPresignedUrl = (action, uploadParts) => {
    return uploadApi.post(
      generatePresignedUrlForUuid(dataAssetId),
      { action, uploadParts },
    );
  };

  const cancelUpload = (fileName, uploadId) => {
    return uploadApi.post(
      generatePresignedUrlForUuid(dataAssetId),
      { action: ACTION_CANCEL_MULTIPART, fileName, uploadId },
    );
  };

  const completeMultpartUpload = (fileName, uploadId, parts) => {
    return uploadApi.post(
      generatePresignedUrlForUuid(dataAssetId),
      { action: ACTION_COMPLETE_MULTIPART, fileName, uploadId, parts },
    );
  };

  const uploadDataPart = (dataPart, presignedUrl) => {
    return axios.put(presignedUrl, dataPart, {
      headers: {
        "Content-type": "text/plain",
      },
    });
  };

  const getJsObjectDataSizeInMiB = (data) => {
    const size = new TextEncoder().encode(JSON.stringify(data)).length;
    const mib = size / 1.049e6;
    return mib;
  };

  const dataIsLargeEnoughForMultipart = (sizeInMiB) => sizeInMiB > 5;

  const chunkString = (str, numberOfParts) => {
    const len = str.length / numberOfParts;
    const creds = str.split("").reduce(
      (acc, val) => {
        let { res, currInd } = acc;
        if (!res[currInd] || res[currInd].length < len) {
          res[currInd] = (res[currInd] || "") + val;
        } else {
          res[++currInd] = val;
        }
        return { res, currInd };
      },
      {
        res: [],
        currInd: 0,
      }
    );
    return creds.res;
  };

  const convertedFields = convertSchemaToFields(schema);
  const fieldsHooks = generateFieldHooks(schema);
  const onRecordChange = generateOnRecordChange(schema);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return convertedFields?.length > 0 &&
    !error &&
    !parsingError &&
    dataAssetId ? (
    <FlatfileButton
      className={classNames("btn btn-primary", styles.flatButton, {
        [styles.flatButton_disabled]: disableButton,
      })}
      fieldHooks={fieldsHooks}
      onRecordChange={onRecordChange}
      devMode
      licenseKey="e6faf1c8-a1fd-41b8-9733-7506810bc74b"
      customer={{ userId: "12345" }}
      settings={{
        type: "Data",
        title: "What data would you like to import?",
        fields: convertedFields,
        theme: {
          global: {
            backgroundColor: "#ffffff",
            textColor: "#0f544c",
            primaryTextColor: "#0f544c",
            secondaryTextColor: "#54575a",
            fontFamily: `"Roboto", sans-serif`,
          },
          buttons: {
            primary: primaryButton,
          },
        },
        managed: true,
      }}
      onData={async (results) => {
        try {
          const dataSizeInMiB = getJsObjectDataSizeInMiB(results);
          let action = dataIsLargeEnoughForMultipart(dataSizeInMiB)
            ? ACTION_MULTIPART
            : ACTION_SINGLE;
          if (action === ACTION_SINGLE) {
            const res = await getPresignedUrl(action);
            if (typeof res.data?.presignedUrl !== "string")
              throw new Error("Upload url not formatted properly in response");
            await axios.put(res.data?.presignedUrl, results);
          } else {
            const amountOfUploadParts = Math.floor(dataSizeInMiB / 5);
            const res = await getPresignedUrl(action, amountOfUploadParts);
            const presignedUrls = res.data?.presignedUrls;
            const uploadId = res.data?.uploadId;
            const fileName = res.data?.fileName;
            if (!Array.isArray(res.data?.presignedUrls))
              throw new Error("Upload urls not formatted properly in response");
            const chunkedResultsString = chunkString(
              JSON.stringify(results),
              presignedUrls.length
            );
            const uploadPromises = res.data.presignedUrls.map(
              (presignedUrl, index) =>
                uploadDataPart(chunkedResultsString[index], presignedUrl)
            );
            try {
              const allPromisesRes = await Promise.all(uploadPromises);
              const eTagParts = allPromisesRes.map((res) => {
                console.log('RES: ', res);
                console.log('RES HEADERS: ', res?.headers);
                if (typeof res?.headers.etag !== "string") {
                  throw new Error("Did not properly upload all parts of data");
                }
                const url = new URL(res.config.url);
                const partNumber = url.searchParams.get("partNumber");
                return {
                  ETag: res.headers.etag.replace('"', "").replace('"', ""),
                  PartNumber: Number(partNumber),
                };
              });
              await completeMultpartUpload(fileName, uploadId, eTagParts);
            } catch (e) {
              console.error(e);
              if (uploadId && fileName) {
                cancelUpload(fileName, uploadId);
              }
              return "Could not upload data";
            }
          }
        } catch (e) {
          console.error(e);
          toast.error("Could not upload data");
          return "Could not upload data";
        }
        return "Successfully sent data";
      }}
    >
      Add Data
    </FlatfileButton>
  ) : (
    <div />
  );
};

export default Upload;
