import * as actionTypes from "./actionTypes";
import { AuthServerState } from "./types";

const initialState: AuthServerState = {
  users: [],
  clients: [],
  accessTokenInfo: null,
  idTokenInfo: null,
};

const reducer = (state = initialState, action: object): AuthServerState => {
  switch (action.type) {
    case actionTypes.REMOVE_AUTH_INFO:
      return {
        ...state,
        accessTokenInfo: null,
        idTokenInfo: null,
      };
    case actionTypes.SET_ACCESS_TOKEN_INFO:
      return {
        ...state,
        accessTokenInfo: action.accessTokenInfo,
      };
    case actionTypes.SET_ID_TOKEN_INFO:
      return {
        ...state,
        idTokenInfo: action.idTokenInfo,
      };
    default:
      return state;
  }
};

export default reducer;
