import jwtDecode from "jwt-decode";

export const checkIfTokenExpired = (token) => {
  if (typeof token !== "string") return true;
  try {
    const valid = jwtDecode(token).exp < Date.now() / 1000;
    return valid;
  } catch (e) {
    return false;
  }
};
